<template>
  <div class="base-input input-style base-select">
    <v-select
      :items="items"
      outlined
      :placeholder="placeholder"
      dense
      height="36px"
      max-height="36px"
      color="darkblue"
      :hide-details="hideDetails"
      :item-value="itemValue"
      :item-text="itemText"
      :value="value"
      :rules="rules"
      :return-object="returnObject"
      :readonly="readOnly"
      menu-props="auto"
      :multiple="multiple"
      @input="$emit('input', $event)"
    >
      <template v-if="slotOptions">
        <div>item</div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ""
    },
    itemValue: {
      type: String,
      default: "value"
    },
    itemText: {
      type: String,
      default: "title"
    },
    value: {
      validator: () => true
    },
    rules: {
      type: Array,
      default: () => [(v) => !!v || ""]
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    slotOptions: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss"></style>
