<template>
  <div class="text-center white px-7 py-3 delete-confirm">
      <p class="priamry--text">Are You Sure Want To Delete ?</p>
      <div class="d-flex align-center justify-space-between mt-3">
          <v-btn color="gray" @click="$emit('closeDialog')">No</v-btn>
          <v-btn color="primary" @click="$emit('confirmAccess')">Yes</v-btn>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.delete-confirm {
  z-index: 9999999999999 !important;
}
</style>