import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
  // base: process.env.BASE_URL,
});
router.beforeEach(async (to, from, next) => {
  if (to.name == "Booking Request") {
    next();
    return;
  }
  const isLogin = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLogin) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    if (isLogin && to.matched.some((record) => record.meta.auth)) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  }
});

export default router;
