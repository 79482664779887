<template>
  <div class="base-input base-textarea">
    <v-textarea
      outlined
      :placeholder="placeholder"
      :rows="rows"
      dense
      color="red"
      hide-details="true"
      :rules="rules"
      :value="value"
      :auto-grow="false"
      no-resize
      @input="$emit('input', $event)"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      validator: () => true,
    },
    rows: {
      type: Number,
      default: 3,
    },
    rules:{
      type: Array,
      default: () => [(v) => !!v || 'this field is require']
    }
  },
};
</script>

<style lang="scss">
.base-textarea {
  .v-input__slot {
    fieldset {
      border: 1px solid #e2e5ed !important;
    }
    .v-input__append-inner {
      margin-top: 5px !important;
    }
  }
  .error--text {
    .v-input__slot {
      fieldset {
        border: 1px solid #e44358 !important;
      }
    }
  }
}
</style>
