import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    text: "",
    color: "",
    timeout: "",
    permissions: [],
    roles: "",
    attractionTab: "miniguide",
    profile:'',
    loading: false
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    loading(state) {
      return state.loading;
    },
    permissions(state) {
      return state.permissions;
    },
    attractionTab(state) {
      return state.attractionTab;
    },
    profile(state) {
      return state.profile;
    },
    roles(state) {
      return state.roles;
    },
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.timeout = payload.timeout;
    },
    SET_USER(_, payload) {
      localStorage.setItem("token", payload.token);
      localStorage.setItem("name", payload.name);
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("role", payload.role);
      localStorage.setItem("userID", payload.id);

      router.go("/");
    },
    REMOVE_USER() {
      localStorage.removeItem("token");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("userID");
      router.go();
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ROLE(state, payload) {
      state.roles = payload;
      state.permissions = payload[0].permissions;
    },
    SET_ATTRACTIONTAB(state, payload) {
      state.attractionTab = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit("SHOW_MESSAGE", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    logout({ commit }) {
      commit("REMOVE_USER");
    },
    loading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    setRole({ commit }, payload) {
      commit("SET_ROLE", payload);
    },
    checkPermission({ state }, payload) {
      let enable = false;
      let arr = state.permissions.map((per) => {
        return {
          [per.name.split(", ")[0]]: [per.name.split(", ")[1]],
        };
      });
      let object = arr.map(
        (obj) =>
          Object.assign(obj, {
            [Object.keys(obj)[0]]: Object.values(obj)[0][0],
          }),
        {}
      );
      object.forEach((per) => {
        if (
          Object.keys(per)[0] == Object.keys(payload)[0] &&
          Object.values(per)[0] == Object.values(payload)[0]
        ) {
          enable = true;
        }
      });
      return enable;
    },
    setAttractionTab({ commit }, payload) {
      commit("SET_ATTRACTIONTAB", payload);
    },
    setProfile({commit}, payload){
      commit('SET_PROFILE', payload)
    }
  },
});
