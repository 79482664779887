const routes = [
  {
    path: "/booking-request/:id",
    name: "Booking Request",
    component: () => import("../views/booking-request/Index.vue"),
    meta: { auth: false, permission: "all" }
  },
  {
    path: "/login",
    name: "Auth",
    component: () => import("../views/auth/login/Index.vue"),
    meta: { auth: true, permission: "all" }
  },
  {
    path: "/forget-password",
    name: "Auth",
    component: () => import("../views/auth/forget/Index.vue"),
    meta: { auth: true, permission: "all" }
  },
  {
    path: "/",
    component: () => import("../layout/Layout.vue"),
    meta: { requiresAuth: true, permission: "all" },

    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/home/Index.vue"),
        meta: { permission: "all" }
      },
      {
        path: "/sales",
        name: "Sales",
        component: () => import("../views/sales/Index.vue"),
        meta: { permission: "all" }
      },
      {
        path: "/booking",
        name: "Bookings",
        component: () => import("../views/booking/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/bookings",
        name: "Adventures",
        component: () => import("../views/booking/all-bookings/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/bookings/:id",
        name: "Adventures",
        component: () => import("../views/booking/all-bookings/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/custom-bookings",
        name: "Custom",
        component: () => import("../views/custom-bookings/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/custom-bookings/:id",
        name: "Custom",
        component: () => import("../views/custom-bookings/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/booking/:id",
        name: "Booking",
        component: () => import("../views/booking/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/user-experience",
        name: "User Experience",
        component: () => import("../views/user-experience/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/custom-experiences",
        name: "Custom Experiences",
        component: () => import("../views/custom-experiences/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/custom-experiences/:id",
        name: "Custom Experiences",
        component: () => import("../views/custom-experiences/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/discounted-guesthouses",
        name: "Discounted Guesthouses",
        component: () => import("../views/discounted-guesthouses/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/deleted-discounted-guesthouses/:id",
        name: "Deletd Discounted Guesthouses",
        component: () =>
          import("../views/recycle/DiscountedGuesthouses/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/deleted-discounted-guesthouses",
        name: "Deleted Discounted Guesthouses",
        component: () =>
          import("../views/recycle/DiscountedGuesthouses/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/deleted-villages/:id",
        name: "Deleted Villages",
        component: () => import("../views/recycle/Villages/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/deleted-villages",
        name: "Deleted Villages",
        component: () => import("../views/recycle/Villages/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/discounted-guesthouses/:id",
        name: "Discounted Guesthouses",
        component: () => import("../views/discounted-guesthouses/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/discount-experiences",
        name: "Discounted",
        component: () => import("../views/discount-experiences/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/discount-experiences/:id",
        name: "Discounted",
        component: () => import("../views/discount-experiences/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/edit-custom-experience/:id",
        name: "Edit Experience",
        component: () => import("../views/user-experience/Index.vue"),
        meta: { permission: { Experiences: "edit" } }
      },
      {
        path: "/experiences",
        name: "Experiences",
        component: () => import("../views/experiences/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/guesthouses",
        name: "Guesthouses",
        component: () => import("../views/guesthouses/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/guesthouses/:id",
        name: "Guesthouses",
        component: () => import("../views/guesthouses/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/deleted-experiences",
        name: "Deleted Experiences",
        component: () => import("../views/recycle/Experiences/Index.vue"),
        meta: { permission: { "Deleted Experiences": "delete" } }
      },
      {
        path: "/experiences/:id",
        name: "Experiences",
        component: () => import("../views/experiences/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/deleted-experiences/:id",
        name: "Deleted Experiences",
        component: () => import("../views/recycle/Experiences/Index.vue"),
        meta: { permission: { "Deleted Experiences": "read" } }
      },
      {
        path: "/coupon",
        name: "Coupon",
        component: () => import("../views/coupon/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      {
        path: "/chat",
        name: "Chat",
        component: () => import("../views/chat/Index.vue"),
        meta: { permission: "all" }
      },
      {
        path: "/chat/:id",
        name: "Chat",
        component: () => import("../views/chat/Index.vue"),
        meta: { permission: "all" }
      },
      {
        path: "/hosts",
        name: "Hosts",
        component: () => import("../views/hosts/Index.vue"),
        meta: { permission: { Hosts: "read" } }
      },
      {
        path: "/hosts/account/:id",
        name: "Host Account",
        component: () => import("../views/hosts/account/Index.vue"),
        meta: { permission: { Hosts: "read" } }
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("../views/users/Index.vue"),
        meta: { permission: { Users: "read" } }
      },
      {
        path: "/users/:id",
        name: "Users",
        component: () => import("../views/users/Index.vue"),
        meta: { permission: { Users: "read" } }
      },
      {
        path: "/messages",
        name: "Messages",
        component: () => import("../views/chat/Index.vue"),
        meta: { permission: "all" }
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () => import("../views/notifications/Index.vue"),
        meta: { permission: { Notifications: "add" } }
      },
      {
        path: "/categories",
        name: "Categories",
        component: () => import("../views/categories/Index.vue"),
        meta: { permission: { Categories: "read" } }
      },
      {
        path: "/attraction-categories",
        name: "Attraction Categories",
        component: () => import("../views/attraction-categories/Index.vue"),
        meta: { permission: { Categories: "read" } }
      },
      {
        path: "/roles",
        name: "Roles",
        component: () => import("../views/roles/Index.vue"),
        meta: { permission: "all" }
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/profile/Index.vue"),
        meta: { permission: "all" }
      },

      // miniguides
      {
        path: "/attractions",
        name: "Attractions",
        component: () => import("../views/attractions/Index.vue"),
        meta: { permission: { Miniguides: "read" } }
      },
      {
        path: "/deleted-attractions",
        name: "Deleted Attraction",
        component: () => import("../views/recycle/Attractions/Index.vue"),
        meta: { permission: { "Deleted Attractions": "read" } }
      },
      // miniguides (attractions) list & id
      {
        path: "/attractions/:id",
        name: "Attractions",
        component: () => import("../views/attractions/Index.vue"),
        meta: { permission: { Miniguides: "read" } }
      },
      // miniguides (attractions) list & id
      {
        path: "/deleted-attractions/:id",
        name: "Miniguide",
        component: () => import("../views/recycle/Attractions/Index.vue"),
        meta: { permission: { "Deleted Attractions": "read" } }
      },

      // villages page list & id
      {
        path: "/villages",
        name: "Villages",
        component: () => import("../views/villages/Index.vue"),
        meta: { permission: { Villages: "read" } }
      },
      // villages page list & id
      {
        path: "/villages/:id",
        name: "Villages",
        component: () => import("../views/villages/Index.vue"),
        meta: { permission: { Villages: "read" } }
      },
      // collections page list & id
      {
        path: "/collections",
        name: "Collections",
        component: () => import("../views/collections/Index.vue"),
        meta: { permission: { Collections: "read" } }
      },
      // collections page list & id
      {
        path: "/collections/:id",
        name: "Collections",
        component: () => import("../views/collections/Index.vue"),
        meta: { permission: { Collections: "read" } }
      },
      // activity stat
      {
        path: "/activity-stats",
        name: "Activity Stats",
        component: () => import("../views/activity-stats/Index.vue"),
        meta: { permission: { Stats: "read" } }
      },
      {
        path: "/activity-stats/:id",
        name: "Activity Stats",
        component: () => import("../views/activity-stats/Index.vue"),
        meta: { permission: { Stats: "read" } }
      },
      // districts
      {
        path: "/districts",
        name: "Districts",
        component: () => import("../views/districts/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      // locations
      {
        path: "/locations",
        name: "Locations",
        component: () => import("../views/location/Index.vue"),
        meta: { permission: { Experiences: "read" } }
      },
      // filters
      {
        path: "/filters",
        name: "Filters",
        component: () => import("../views/filters/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/library/trips",
        name: "LibraryTrips",
        component: () => import("../views/library/trips/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/library/trips/:id",
        name: "LibraryTrips",
        component: () => import("../views/library/trips/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/library/sections",
        name: "LibrarySections",
        component: () => import("../views/library/sections/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/library/sections/:id",
        name: "LibrarySections",
        component: () => import("../views/library/sections/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/library/bookings",
        name: "LibraryBookings",
        component: () => import("../views/library/bookings/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/library/bookings/:id",
        name: "LibraryBookings",
        component: () => import("../views/library/bookings/Index.vue"),
        meta: { permission: { Filters: "read" } }
      },
      {
        path: "/scraper",
        name: "Scraper",
        component: () => import("../views/scraper/Index.vue"),
        meta: { permission: { Filters: "read" } }
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "*",
    meta: { permission: "all" }

    //   component: () => import("../views/Error404.vue"),
  }
];

export default routes;

// {Experiences: 'read'}
// {Miniguides: 'read'}
// {Villages: 'read'}
// {Hosts: 'read'}
// {Collections: 'read'}

// {Experiences: 'add'}
// {Miniguides: 'add'}

// read
// add
// edit
// delete
