import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "vue-transitions-css";

Vue.use(Vuetify);
const opts = {
  theme: {
    customProperties: true,
    themes: {
      light: {
        primary: "#252529",
        secondary: "#f28379",
        black: "#3E3F42",
        "black-grey": "#9EA0A5",
        "white-grey": "#6B6C6F",
        darkblue: "#555891",
        whitebg: "#EAEDF3",
        gray: "#808080",
        greyicon: "#CECFD2",
        graybg: "#fafbfc",
        blue: "#6758F3",
        green: "#2D9C3C",
        purple: "#BF6991",
        orange: "#F29727",
        lightblue: "#73B2D9",
        skyblue: "#D8DCE6",
        yellow: "#FACF55",
      },
    },
  },
};

export default new Vuetify(opts);
