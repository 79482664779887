import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios.js";
import "./assets/style/main.scss";

import BaseInput from "@/components/base/Input";
import BaseSelect from "@/components/base/Select";
import BaseTextArea from "@/components/base/TextArea";
import BaseDate from "@/components/base/Date";
import LoadingBtn from "@/components/base/LoadingButton";

// slick slier
// slick
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// google maps
import GmapMap from "vue2-google-maps/dist/components/map.vue";
import GmapMarker from "vue2-google-maps/src/components/marker";
import GMapAutocomplete from "vue2-google-maps/dist/components/autocomplete.vue";
import DatetimePicker from "vuetify-datetime-picker";
import DeleteConfirm from "./components/dialogs/DeleteConfirm.vue";
import Vuelidate from "vuelidate";

import InfiniteScroll from "v-infinite-scroll";
import "v-infinite-scroll/dist/v-infinite-scroll.css";
Vue.use(InfiniteScroll);

Vue.use(Vuelidate);
Vue.use(DatetimePicker);
Vue.use(axios);
Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBks-gFEmIsCVp68MMtX2JYwJrv6znoW8Q",
    libraries: "places",
    region: "lb",
    language: "en"
  }
});
Vue.component("GmapMap", GmapMap);
Vue.component("GmapMarker", GmapMarker);
Vue.component("GMapAutocomplete", GMapAutocomplete);
Vue.component("slick-slider", VueSlickCarousel);
Vue.component("base-input", BaseInput);
Vue.component("base-select", BaseSelect);
Vue.component("base-textarea", BaseTextArea);
Vue.component("base-date", BaseDate);
Vue.component("delete-confirm", DeleteConfirm);
Vue.component("loading-btn", LoadingBtn);
Vue.mixin({
  computed: {
    google() {
      return window.google;
    }
  }
});

Vue.prototype.$convertTo12Hour = function (time24) {
  if (time24.split(" ").length == 1) {
    time24 = time24
      .split(":")
      .map((segment) => segment.padStart(2, "0"))
      .join(":");
    const [hour, minute] = time24.split(":");
    let h = parseInt(hour, 10);
    let ampm = "AM";

    if (h >= 12) {
      ampm = "PM";
    }

    if (h > 12) {
      h = h - 12;
    }

    if (h === 0) {
      h = 12;
    }

    // Add leading zero to single-digit minutes
    const m = minute.padStart(2, "0");

    return `${h}:${m} ${ampm}`;
  } else {
    return time24;
  }
};

Vue.prototype.$convertTo24Hour = function (time12) {
  if (time12.split(" ").length == 2) {
    time12 = time12
      .split(" ")
      .map((time) => {
        const times = time.split(":");
        if (times && times.length > 0) {
          return times.map((segment) => segment.padStart(2, "0")).join(":");
        }
        return time;
      })
      .join(" ");
    const [time, ampm] = time12.split(" ");
    const [hour, minute] = time.split(":");
    let h = parseInt(hour, 10);

    if (ampm === "AM" && h === 12) {
      h = 0;
    } else if (ampm === "PM" && h < 12) {
      h += 12;
    }

    const hour24 = h.toString().padStart(2, "0");

    return `${hour24}:${minute}`;
  } else {
    return time12;
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  data: {
    //only place where data is not a function
    languages: ["en"],
    countries: [{ languages: ["en"], short: "LB", name: "Lebanon" }],
    language: "en",
    country: { languages: ["en"], short: "LB", name: "Lebanon" }
  },
  watch: {
    language: {
      handler() {
        var shouldReload = false;
        if (localStorage.getItem("language") !== this.language) {
          shouldReload = true;
        }
        localStorage.setItem("language", this.language);
        if (shouldReload) {
          location.reload();
        }
      }
    },
    country: {
      handler() {
        var shouldReload = false;
        if (
          JSON.parse(localStorage.getItem("country")).short !==
          this.country.short
        ) {
          shouldReload = true;
        }
        localStorage.setItem("country", JSON.stringify(this.country));
        this.languages = this.country.languages;
        if (
          this.languages.length > 0 &&
          (localStorage.getItem("language") === undefined ||
            !this.languages.includes(localStorage.getItem("language")))
        ) {
          this.language = this.languages[0];
        }
        if (shouldReload) {
          location.reload();
        }
      }
    }
  },
  async created() {
    const response = await this.$axios.get(`/country`);
    if (response && response.data && response.data.data) {
      this.countries = response.data.data;
    }
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", this.language);
    } else {
      this.language = localStorage.getItem("language");
    }
    if (!localStorage.getItem("country")) {
      localStorage.setItem("country", JSON.stringify(this.country));
    } else if (localStorage.getItem("country")) {
      this.country = JSON.parse(localStorage.getItem("country"));
    }
  }
}).$mount("#app");
