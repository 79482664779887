<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="input-style"
          outlined
          label="Select Date"
          dense
          :hide-details="true"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :value="value ? value.slice(0, 10) : value"
          @input="$emit('input', $event)"
          :clearable="clearable"
          @click:clear="clearHandler"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title scrollable @input="inputChanged" :multiple="multiple">
        <div v-if="multiple">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="okHandler"> OK </v-btn>
        </div>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    date: "",
    menu: false
  }),
  methods: {
    okHandler() {
      this.menu = false;
      this.$emit("valueHandler", this.date);
    },
    inputChanged() {
      if (!this.multiple) {
        this.okHandler();
      }
    },
    clearHandler() {
      this.date = ""
      this.okHandler();
    }
  },
  created() {
    if (this.value) {
      this.date = this.value;
    }
  }
};
</script>

<style></style>
