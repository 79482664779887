import Vue from "vue";
import axios from "axios";
import store from "../store";

if (localStorage.getItem("isDebug") === "true") {
  axios.defaults.baseURL = "https://testing.jedo.app";
} else {
  axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;
}
window.axios = axios;
Vue.prototype.$axios = axios;
axios.defaults.headers.common = {
  "x-access-token": `${localStorage.getItem("token")}`,
  "x-platform": 'admin-web',
  "x-country": `${JSON.parse(localStorage.getItem("country"))?.short || 'LB'}`,
  "x-lang": `${localStorage.getItem("language") || 'en'}`
};

export default async () => {
  // show loader in each request
  window.axios.interceptors.request.use(
    (confiq) => {
      if (localStorage.getItem("isDebug") === "true") {
        axios.defaults.baseURL = "https://testing.jedo.app";
      } else {
        axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;
      }
      if (confiq.method != "get") {
        store.dispatch("loading", true);
      }
      return confiq;
    },
    (erorr) => {
      return erorr;
    }
  );

  window.axios.interceptors.response.use(
    function (response) {
      store.dispatch("loading", false);
      return response;
    },
    async function (error) {
      store.dispatch("loading", false);
      // response with status code > 200 is here
      if (error && error.response) {
        return error.response.data;
      } else {
        return { message: "Lah lah lah! Something went wrong!" };
      }
    }
  );
};

// export default axios;
