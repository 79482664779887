<template>
  <v-app class="app" v-if="loadApp">
    <snackbar></snackbar>
    <router-view />
    <div class="loading white--text" v-if="loading">Loading...</div>
  </v-app>
</template>

<script>
import Snackbar from "@/components/global/Snackbar";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    loadApp: false,
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
  async created() {
    let { data } = await this.$axios.get(`roles`);
    if (data && data.success) {
      let userRole = data.roles.filter(
        (rol) => rol.name.toLowerCase() == localStorage.getItem("role")
      );
      this.$store.dispatch("setRole", userRole);
    }
    this.loadApp = true;

  },
};
</script>

<style lang="scss">
.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fade 1s forwards;
}

@keyframes fade {
  to {
    opacity: 1;
  }
}
</style>
